import axios from "axios";
import store from "../store/store";
import router from "../router/router";

const service = axios.create({
  // https://api.showings.cn
  // https://chaoyuan.dev8.top
  // http://192.168.1.5:10001
  baseURL: "https://api.showings.cn",
  timeout: 5000,
});

service.interceptors.request.use(
  function (config) {
    const TOKEN = JSON.parse(localStorage.getItem("token"));
    if (TOKEN !== null && TOKEN !== "") {
      config.headers["ultra-auth"] = TOKEN;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  function (response) {
    if (response.status === 200) {
      if (response.data.code === 401) {
        store.state.userInfo = "";
        store.state.token = "";
        store.state.userId = null;
        localStorage.removeItem("userInfo");
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        router.push({
          path: "/",
          query: {
            name: 'SHOWINGS首映式',
          },
        });
      } else {
        return Promise.resolve(response);
      }
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 201:
          console.log("创建成功");
          break;
        case 204:
          console.log("删除成功");
          break;
        case 400:
          console.log("请求的地址不存在或者包含不支持的参数");
          break;
        case 401:
          console.log("你还没未授权");
          break;
        case 403:
          console.log("禁止访问");
          break;
        case 404:
          console.log("请求资源不存在");
          break;
        case 422:
          console.log("[POST/PUT/PATCH] 当创建一个对象时，发生一个验证错误");
          break;
        case 500:
          console.log("内部错误");
          break;
        case 503:
          console.log("服务器未响应");
          break;
        default:
          console.log("出错啦！");
      }
      return Promise.reject(error.response);
    }
  }
);

export function get(url, params) {
  return new Promise((resolve, reject) => {
    service
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    service({
      method: "post",
      url: url,
      data: data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export default service;
