import request from "@/utils/request";

const homeApi = {
  // 首页banner
  postBannerData: function (data) {
    return request({
      url: "ultra-component/banner/list",
      method: "POST",
      data,
    });
  },
  // 首页素材
  postMaterialData: function (data) {
    return request({
      url: "ultra-component/material/module",
      method: "POST",
      data,
    });
  },
  // 素材详情
  postMaterialInfoData: function (data) {
    return request({
      url: "ultra-component/material/material-info",
      method: "POST",
      data,
    });
  },
  // 素材详情的推荐列表
  postRecommendData: function (data) {
    return request({
      url: "ultra-component/material/detail/recommend/list",
      method: "POST",
      data,
    });
  },
  // 上传文件
  postUploadDoc: function (data) {
    return request({
      url: "ultra-component/attachment/upload",
      method: "POST",
      data,
    });
  },
  // 分类列表
  getCategoryData: function (data) {
    return request({
      url: "ultra-component/category/list",
      method: "GET",
      data,
    });
  },
  // 平台列表
  getPlateData: function (data) {
    return request({
      url: "ultra-component/material/support-platform/list",
      method: "GET",
      data,
    });
  },
  // 搜索
  postSearchData: function (data) {
    return request({
      url: "ultra-component/material/page",
      method: "POST",
      data,
    });
  },
  // 创建订单
  postCreateOrder: function (data) {
    return request({
      url: "ultra-order/order/create",
      method: "POST",
      data,
    })
  },
  // 检查支付
  postCheckPayment: function (data) {
    return request({
      url: "ultra-order/order/check-pay-status",
      method: "POST",
      data,
    })
  },
  // 下载
  postDownload: function (data) {
    return request({
      url: "ultra-component/download/download",
      method: "POST",
      data,
    });
  },
};

export default homeApi;
