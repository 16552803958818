<template>
  <el-dialog custom-class="login-dialog" width="700px" :visible.sync="visible">
    <div class="dialog-left">
      <img src="https://oss.cycsmeta.com/cloud/230310/17/2023031017372521810/showings_bill.png?x-oss-process=image/resize,p_20,limit_0,w_270" alt="">
    </div>
    <div class="dialog-right">
      <div class="dialog-title">{{isPassword?'密码登录':'验证码登录'}}</div>
      <div class="dialog-phone">
        <div class="dialog-phone-area">+86</div>
        <div class="dialog-phone-number">
          <input type="number" v-model="phoneNumber" maxlength="11" placeholder="手机号"/>
        </div>
      </div>
      <div class="dialog-verification">
        <div class="dialog-verification-code">
          <input v-if="!isPassword" type="number" v-model="verificationCode" placeholder="请输入验证码"/>
          <input v-if="isPassword" type="password" v-model="password" placeholder="请输入密码"/>
        </div>
        <template v-if="!isPassword">
          <div class="dialog-verification-btn" v-show="time >= 60" @click="getCodeData()">
            获取验证码
          </div>
          <div class="dialog-verification-btn" v-show="time < 60">
            {{ time + "s后获取" }}
          </div>
        </template>
        <template v-if="isPassword">
          <div class="dialog-verification-psdword" @click="openRegisterDialog(false)">忘记密码?</div>
        </template>
      </div>
      <div class="dialog-btn" @click="onLogin()">登录</div>
      <div class="dialog-cut" @click="isPassword = !isPassword">{{isPassword?'验证码登录':'密码登录'}}</div>
      <div class="dialog-register">
        <span @click="openRegisterDialog(true)">注册</span>
      </div>
      <div class="dialog-agreement">
        注册和登录即代表您已同意
        <a
            href="https://showings.cycsmeta.com/agreement/agreement.html"
            target="_blank"
        >《SHOWINGS首映式用户注册协议》</a
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import loginApi from "@/api/login";
import {Loading} from "element-ui";
import userApi from "@/api/user";

export default {
  inject: ["reload"],
  data () {
    return {
      // 登录弹框参数
      visible: false, //登录弹框显隐
      phoneNumber: "", //手机号码
      verificationCode: "", //验证码
      password: "", //密码
      time: 60, //倒计时
      isCountDown: false, //是否开始倒计时
      timer: null, //定时器
      isRegister: false, //注册信息显隐
      isPassword: false, //密码登录显隐
    }
  },
  methods: {
    // 打开注册弹框
    openRegisterDialog(boolean) {
      this.$parent.openRegisterDialog(boolean);
    },
    // 获取验证码
    getCodeData() {
      if (!this.timer && !this.isCountDown) {
        if (!this.phoneNumber) {
          this.$message.error("请输入手机号！");
        } else {
          this.openCountDown();
          loginApi.getCodeData(this.phoneNumber).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
              clearInterval(this.timer);
              this.time = 60;
              this.timer = null;
            }
          });
        }
      }
    },
    // 验证码倒计时
    openCountDown() {
      this.timer = setInterval(() => {
        if (this.time > 0 && this.time <= 60) {
          this.time--;
        } else {
          clearInterval(this.timer);
          this.time = 60;
          this.timer = null;
        }
      }, 1000);
    },
    // 登录
    onLogin() {
      if (!this.isPassword) {
        if (!this.phoneNumber) {
          this.$message.error("请输入手机号！");
        } else if (!this.verificationCode) {
          this.$message.error("请输入验证码！");
        } else {
          this.codeLogin(); //验证码登录
        }
      } else {
        if (!this.phoneNumber) {
          this.$message.error("请输入手机号！");
        } else if (!this.password) {
          this.$message.error("请输入密码！");
        } else {
          this.passWordLogin(); //密码登录
        }
      }
    },
    // 密码登录
    passWordLogin() {
      let loadingInstance = Loading.service({
        lock: true,
        text: "登录中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      loginApi.postLogin({
        mobile: this.phoneNumber,
        password: this.password,
        grant_type: "password",
        scope: "all",
        source: 1,
      }).then((res) => {
        if (res.data.code == 200) {
          loadingInstance.close();
          this.visible = false;
          this.phoneNumber = "";
          this.verificationCode = "";
          this.$store.commit("SET_USER", res.data.data);
          this.$store.commit("SET_TOKEN", res.data.data.accessToken);
          this.$store.commit("SET_USERID", res.data.data.userId);
          this.getPermissions(); //获取用户权限
        } else {
          loadingInstance.close();
          this.$message.error(res.data.msg);
        }
      });
    },
    // 验证码登录
    codeLogin() {
      let loadingInstance = Loading.service({
        lock: true,
        text: "登录中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      loginApi.postLogin({
        mobile: this.phoneNumber,
        code: this.verificationCode,
        grant_type: "sms",
        scope: "all",
        source: 1,
      }).then((res) => {
        if (res.data.code == 200) {
          loadingInstance.close();
          this.visible = false;
          this.phoneNumber = "";
          this.verificationCode = "";
          this.$store.commit("SET_USER", res.data.data);
          this.$store.commit("SET_TOKEN", res.data.data.accessToken);
          this.$store.commit("SET_USERID", res.data.data.userId);
          this.getPermissions(); //获取用户权限
        } else {
          loadingInstance.close();
          this.$message.error(res.data.msg);
        }
      });
    },
    // 获取用户权限
    getPermissions() {
      userApi.getUserPermissions({
        userId: this.$store.state.userId
      }).then(res => {
        this.$store.commit("SET_AUTH", res.data.data.authCode);
        this.reload(); //刷新页面
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .login-dialog {
  margin-top: 21vh !important;
  padding: 30px;
  box-sizing: border-box;
  background: #171918;
  border-radius: 10px;
  border: 1px solid #555555;

  .el-dialog__headerbtn {
    font-size: 24px;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .dialog-left {
      width: 270px;
      height: 340px;
      margin-right: 30px;
      border-radius: 1px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .dialog-right {
      width: 400px;
      height: 340px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .dialog-title {
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: 600;
        color: #F9F9F9;
      }

      .dialog-phone {
        width: 100%;
        margin-bottom: 14px;
        background: linear-gradient(134deg,
        rgba(238, 238, 238, 0.2) 0%,
        rgba(216, 216, 216, 0.1) 100%);
        border: 2px solid;
        box-sizing: border-box;
        border-image: linear-gradient(311deg,
        rgba(157, 152, 253, 0.2),
        rgba(255, 255, 255, 0.2)) 2 2;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dialog-phone-area {
          width: 25%;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #f9f9f9;
          border-right: 2px solid rgba(70, 76, 85, 0.8);
        }

        .dialog-phone-number {
          width: 75%;
          padding: 9px 24px;
          box-sizing: border-box;

          input {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            border: none;
            background-color: transparent;
            outline: none;
          }
        }
      }

      .dialog-verification {
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dialog-verification-code {
          width: 60%;
          background: linear-gradient(134deg,
          rgba(238, 238, 238, 0.2) 0%,
          rgba(216, 216, 216, 0.1) 100%);
          padding: 9px 32px;
          border: 2px solid;
          box-sizing: border-box;
          border-image: linear-gradient(311deg,
          rgba(157, 152, 253, 0.2),
          rgba(255, 255, 255, 0.2)) 2 2;
          backdrop-filter: blur(30px);

          input {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            border: none;
            background-color: transparent;
            outline: none;
            text-align: left;
          }
        }

        .dialog-verification-btn {
          width: 35%;
          background: linear-gradient(134deg,
          rgba(238, 238, 238, 0.2) 0%,
          rgba(216, 216, 216, 0.1) 100%);
          padding: 9px 10px;
          border: 2px solid;
          box-sizing: border-box;
          border-image: linear-gradient(311deg,
          rgba(157, 152, 253, 0.2),
          rgba(255, 255, 255, 0.2)) 2 2;
          backdrop-filter: blur(30px);
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #f9f9f9;
          cursor: pointer;
        }

        .dialog-verification-psdword {
          width: 35%;
          padding: 9px 10px;
          box-sizing: border-box;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #9A9A9A;
          cursor: pointer;
        }
      }

      .dialog-btn {
        width: 100%;
        height: 35px;
        background-color: #03bd61;
        border-radius: 4px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        font-weight: 400;
        color: #f9f9f9;
        cursor: pointer;
      }

      .dialog-cut {
        width: 100%;
        height: 35px;
        margin-top: 18px;
        background-color: #3F4040;
        border-radius: 4px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        font-weight: 400;
        color: #f9f9f9;
        cursor: pointer;
      }

      .dialog-register {
        width: 100%;
        padding-right: 12px;
        box-sizing: border-box;
        margin-top: 14px;
        text-align: right;

        span {
          color: #03bd61;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .dialog-agreement {
        width: 100%;
        margin-top: 14px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #f9f9f9;

        a {
          color: #03bd61;
        }
      }
    }
  }
}
</style>