import request from "@/utils/request";

const loginApi = {
  // 获取验证码
  getCodeData: function (params) {
    return request({
      url: "ultra-uaa/auth/sms-code?mobile=" + params,
      method: "GET",
    });
  },
  // 注册
  postRegist: function (data) {
    return request({
      url: "ultra-user/user/regist",
      method: "POST",
      data,
    });
  },
  // 修改密码
  postChangePassword: function (data) {
    return request({
      url: "ultra-user/user/password/change",
      method: "POST",
      data,
    });
  },
  // 登录
  postLogin: function (data) {
    return request({
      headers: {
        Authorization: "Basic bWF0ZTptYXRlX3NlY3JldA==",
      },
      url: "ultra-uaa/oauth/token",
      method: "POST",
      data,
    });
  },
  // 修改密码
  postRevisePassword: function (data) {
    return request({
      url: "ultra-user/user/password/change",
      method: "POST",
      data,
    });
  },
  // 退出登录
  postLogout: function (data) {
    return request({
      headers: {
        "ultra-auth": data,
      },
      url: "ultra-uaa/auth/logout",
      method: "POST",
    });
  },
};

export default loginApi;
