import request from "@/utils/request";

const userApi = {
  //个人主页信息
  postUserData: function (data) {
    return request({
      url: "ultra-user/user/user-info",
      method: "POST",
      data,
    });
  },
  // 创建收藏夹
  postCreateFavorites: function (data) {
    return request({
      url: "ultra-user/favorite/create",
      method: "POST",
      data,
    });
  },
  // 收藏夹列表
  postFavoritesData: function (data) {
    return request({
      url: "ultra-user/favorite/list",
      method: "POST",
      data,
    });
  },
  // 修改收藏夹名称
  postModifyFavorites: function (data) {
    return request({
      url: "ultra-user/favorite/update",
      method: "POST",
      data,
    });
  },
  // 删除收藏夹
  postDeleteFavorites: function (data) {
    return request({
      url: "ultra-user/favorite/delete",
      method: "POST",
      data,
    });
  },
  // 收藏列表
  postCollectionData: function (data) {
    return request({
      url: "ultra-user/collect/page",
      method: "POST",
      data,
    });
  },
  // 用户收藏
  postUserCollection: function (data) {
    return request({
      url: "ultra-user/collect/add",
      method: "POST",
      data,
    });
  },
  // 取消收藏
  postCancelCollection: function (data) {
    return request({
      url: "ultra-user/collect/cancel",
      method: "POST",
      data,
    });
  },
  // 获取收藏夹id
  postFavoritesId: function (data) {
    return request({
      url: "ultra-user/collect/get/favorite/id",
      method: "POST",
      data,
    });
  },
  // 移动至收藏
  postMoveFavorites: function (data) {
    return request({
      url: "ultra-user/collect/move",
      method: "POST",
      data,
    });
  },
  // 关注
  postAddAttention: function (data) {
    return request({
      url: "ultra-user/attention/add",
      method: "POST",
      data,
    });
  },
  // 取消关注
  postCancelAttention: function (data) {
    return request({
      url: "ultra-user/attention/cancel",
      method: "POST",
      data,
    });
  },
  // 点赞
  postAddLike: function (data) {
    return request({
      url: "ultra-user/user-like/add",
      method: "POST",
      data,
    });
  },
  // 取消点赞
  postCancelLike: function (data) {
    return request({
      url: "ultra-user/user-like/cancel",
      method: "POST",
      data,
    });
  },
  // ta的作品
  postHisWorksList: function (data) {
    return request({
      url: "ultra-user/user/his-works",
      method: "POST",
      data,
    });
  },
  // 我的作品
  getMineWorksList: function (params) {
    return request({
      url: "ultra-user/user/works/list",
      method: "GET",
      params
    });
  },
  // 添加访客记录
  postVisitorsRecord: function (data) {
    return request({
      url: "ultra-user/visitor/add",
      method: "POST",
      data,
    });
  },
  // 下载列表
  postDownloadData: function (data) {
    return request({
      url: "ultra-component/download/page",
      method: "POST",
      data,
    });
  },
  // 编辑资料
  updateUserData: function (data) {
    return request({
      url: "ultra-user/user/update-info",
      method: "POST",
      data,
    });
  },
  // 获取订单列表
  postOrderdata: function (data) {
    return request({
      url: "ultra-order/order/page",
      method: "POST",
      data,
    });
  },
  // 获取充值列表
  postRechargedata: function (params) {
    return request({
      url: "ultra-order/order/recharge/page",
      method: "GET",
      params,
    });
  },
  // 获取直传签名
  postAction: function (url,data) {
    return request({
      url,
      method: "POST",
      data,
    });
  },
  // 充值配置列表
  postEffectiveList: function (params) {
    return request({
      url: "ultra-order/recharge/effective/list",
      method: "GET",
      params,
    });
  },
  // 创建充值订单
  postCreateRecharge: function (data) {
    return request({
      url: "ultra-order/order/recharge",
      method: "POST",
      data,
    });
  },
  // 创建作品
  postCreateWorks: function (data) {
    return request({
      url: "ultra-user/user/works/create",
      method: "POST",
      data,
    });
  },
  // 编辑作品
  postUpdateWorks: function (data) {
    return request({
      url: "ultra-user/user/works/update",
      method: "POST",
      data,
    });
  },
  // 删除作品
  postDeleteWorks: function (data) {
    return request({
      url: "ultra-user/user/works/delete",
      method: "POST",
      data
    })
  },
  // 获取作品信息
  getWorksInfo: function (params) {
    return request({
      url: "ultra-user/user/works/get",
      method: "GET",
      params
    })
  },
  // 获取用户余额
  getUserBalance: function (params) {
    return request({
      url: "ultra-user/user/balance/account/get",
      method: "GET",
      params
    })
  },
  // 获取用户权限
  getUserPermissions: function (params) {
    return request({
      url: "ultra-user/user/permissions/get",
      method: "GET",
      params
    })
  },
};

export default userApi;
