<template>
  <el-dialog custom-class="register-dialog" width="700px" :visible.sync="visible">
    <div class="dialog-left">
      <img src="https://oss.cycsmeta.com/cloud/230310/17/2023031017372521810/showings_bill.png?x-oss-process=image/resize,p_20,limit_0,w_270" alt="">
    </div>
    <div class="dialog-right">
      <div class="dialog-header">
        <div class="dialog-header-back" @click="back">
          <div class="icon">
            <img src="@/assets/images/btn/back.png" alt="">
          </div>
        <!--<span class="text">返回</span>-->
        </div>
        <span class="dialog-header-title">{{isRegister?'注册':'设置密码'}}</span>
      </div>
      <div class="dialog-phone">
        <div class="dialog-phone-area">+86</div>
        <div class="dialog-phone-number">
          <input type="number" v-model="phoneNumber" maxlength="11" placeholder="手机号"/>
        </div>
      </div>
      <div class="dialog-verification">
        <div class="dialog-verification-code">
          <input type="number" v-model="verificationCode" placeholder="请输入验证码"/>
        </div>
        <div class="dialog-verification-btn" v-show="time >= 60" @click="getCodeData()">
          获取验证码
        </div>
        <div class="dialog-verification-btn" v-show="time < 60">
          {{ time + "s后获取" }}
        </div>
      </div>
      <div class="dialog-password">
        <input type="password" v-model="password" placeholder="密码长度6-16位"/>
      </div>
      <div class="dialog-btn" v-if="isRegister" @click="onRegister">注册</div>
      <div class="dialog-btn" v-if="!isRegister" @click="onResetPassword">重置密码</div>
      <div class="dialog-agreement">
        注册和登录即代表您已同意
        <a
            href="https://showings.cycsmeta.com/agreement/agreement.html"
            target="_blank"
        >《SHOWINGS首映式用户注册协议》</a
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import loginApi from "@/api/login";
import {Loading} from "element-ui";

export default {
  inject: ["reload"],
  data () {
    return {
      visible: false, //登录弹框显隐
      phoneNumber: "", //手机号码
      verificationCode: "", //验证码
      password: "", //密码
      time: 60, //倒计时
      isCountDown: false, //是否开始倒计时
      timer: null, //定时器
      isRegister: true, //是否注册
    }
  },
  methods: {
    back() {
      this.$parent.openLoginDialog();
    },
    // 获取验证码
    getCodeData() {
      if (!this.timer && !this.isCountDown) {
        if (!this.phoneNumber) {
          this.$message.error("请输入手机号！");
        } else {
          this.openCountDown();
          loginApi.getCodeData(this.phoneNumber).then((res) => {
            if (res.data.code == 200) {
              this.$message.success(res.data.msg);
            } else {
              this.$message.error(res.data.msg);
              clearInterval(this.timer);
              this.time = 60;
              this.timer = null;
            }
          });
        }
      }
    },
    // 验证码倒计时
    openCountDown() {
      this.timer = setInterval(() => {
        if (this.time > 0 && this.time <= 60) {
          this.time--;
        } else {
          clearInterval(this.timer);
          this.time = 60;
          this.timer = null;
        }
      }, 1000);
    },
    // 注册
    onRegister() {
      if (!this.phoneNumber) {
        this.$message.error("请输入手机号！");
        return false;
      }
      if (!this.verificationCode) {
        this.$message.error("请输入验证码！");
        return false;
      }
      if (!this.password) {
        this.$message.error("请输入密码！");
        return false;
      }
      if (this.password.length < 6 || this.password.length > 16) {
        this.$message.error("密码长度为6-16位！");
        return false;
      }
      let loadingInstance = Loading.service({
        lock: true,
        text: "注册中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      loginApi.postRegist({
        appId: 1,
        mobile: this.phoneNumber,
        code: this.verificationCode,
        password: this.password,
      }).then((res) => {
        if (res.data.code == 200) {
          loadingInstance.close();
          this.visible = false;
          this.phoneNumber = "";
          this.verificationCode = "";
          this.password = "";
          this.$message.success("注册成功");
        } else {
          loadingInstance.close();
          this.$message.error(res.data.msg);
        }
      });
    },
    // 重置密码
    onResetPassword() {
      if (!this.phoneNumber) {
        this.$message.error("请输入手机号！");
        return false;
      }
      if (!this.verificationCode) {
        this.$message.error("请输入验证码！");
        return false;
      }
      if (!this.password) {
        this.$message.error("请输入密码！");
        return false;
      }
      if (this.password.length < 6 || this.password.length > 16) {
        this.$message.error("密码长度为6-16位！");
        return false;
      }
      let loadingInstance = Loading.service({
        lock: true,
        text: "修改密码中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      loginApi.postChangePassword({
        appId: 1,
        mobile: this.phoneNumber,
        code: this.verificationCode,
        password: this.password,
      }).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          loadingInstance.close();
          this.visible = false;
          this.phoneNumber = "";
          this.verificationCode = "";
          this.password = "";
          this.$message.success("重置成功");
        } else {
          loadingInstance.close();
          this.$message.error(res.data.msg);
        }
      });
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .register-dialog {
  margin-top: 21vh !important;
  padding: 30px;
  box-sizing: border-box;
  background: #171918;
  border-radius: 10px;
  border: 1px solid #555555;

  .el-dialog__headerbtn {
    font-size: 24px;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .dialog-left {
      width: 270px;
      height: 340px;
      margin-right: 30px;
      border-radius: 1px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .dialog-right {
      width: 400px;
      height: 340px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .dialog-header {
        width: 100%;
        margin-bottom: 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .dialog-header-back {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          cursor: pointer;

          .icon {
            width: 23px;
            height: 23px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .text {
            margin-left: 6px;
            font-size: 16px;
            color: #F9F9F9;
          }
        }

        .dialog-header-title {
          font-size: 18px;
          font-weight: 600;
          color: #F9F9F9;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }

      .dialog-phone {
        width: 100%;
        background: linear-gradient(134deg,
        rgba(238, 238, 238, 0.2) 0%,
        rgba(216, 216, 216, 0.1) 100%);
        margin-bottom: 14px;
        border: 2px solid;
        box-sizing: border-box;
        border-image: linear-gradient(311deg,
        rgba(157, 152, 253, 0.2),
        rgba(255, 255, 255, 0.2)) 2 2;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dialog-phone-area {
          width: 25%;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #f9f9f9;
          border-right: 2px solid rgba(70, 76, 85, 0.8);
        }

        .dialog-phone-number {
          width: 75%;
          padding: 9px 24px;
          box-sizing: border-box;

          input {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            border: none;
            background-color: transparent;
            outline: none;
          }
        }
      }

      .dialog-verification {
        width: 100%;
        margin-bottom: 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .dialog-verification-code {
          width: 60%;
          background: linear-gradient(134deg,
          rgba(238, 238, 238, 0.2) 0%,
          rgba(216, 216, 216, 0.1) 100%);
          padding: 9px 32px;
          border: 2px solid;
          box-sizing: border-box;
          border-image: linear-gradient(311deg,
          rgba(157, 152, 253, 0.2),
          rgba(255, 255, 255, 0.2)) 2 2;
          backdrop-filter: blur(30px);

          input {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            border: none;
            background-color: transparent;
            outline: none;
            text-align: left;
          }
        }

        .dialog-verification-btn {
          width: 35%;
          background: linear-gradient(134deg,
          rgba(238, 238, 238, 0.2) 0%,
          rgba(216, 216, 216, 0.1) 100%);
          padding: 9px 10px;
          border: 2px solid;
          box-sizing: border-box;
          border-image: linear-gradient(311deg,
          rgba(157, 152, 253, 0.2),
          rgba(255, 255, 255, 0.2)) 2 2;
          backdrop-filter: blur(30px);
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: #f9f9f9;
          cursor: pointer;
        }
      }

      .dialog-password {
        width: 100%;
        margin-bottom: 66px;
        background: linear-gradient(134deg,
        rgba(238, 238, 238, 0.2) 0%,
        rgba(216, 216, 216, 0.1) 100%);
        padding: 9px 32px;
        border: 2px solid;
        box-sizing: border-box;
        border-image: linear-gradient(311deg,
        rgba(157, 152, 253, 0.2),
        rgba(255, 255, 255, 0.2)) 2 2;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          border: none;
          background-color: transparent;
          outline: none;
        }
      }

      .dialog-btn {
        width: 100%;
        height: 35px;
        background-color: #03bd61;
        border-radius: 4px;
        font-size: 16px;
        text-align: center;
        line-height: 35px;
        font-weight: 400;
        color: #f9f9f9;
        cursor: pointer;
      }

      .dialog-agreement {
        width: 100%;
        margin-top: 18px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #f9f9f9;

        a {
          color: #03bd61;
        }
      }
    }
  }
}
</style>